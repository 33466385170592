
import React from 'react';
import { BrowserRouter, Switch, Route, useParams, NavLink } from 'react-router-dom';
import HeaderComponent from './components/header/header.component';
import NotFoundPage from './pages/not-found/not-found.page';
import HomePage from './pages/home/home.page';
import SnackComponent from './components/snack/snack.component';
import LoadingComponent from './components/loading/loading.component';
import ExportarFrasePage from './pages/exportar-frase/exportar-frase.page';
import FooterComponent from './components/footer/footer.component';
import PoliticaPage from './pages/politica/politica.page';
import { Button } from '@mui/material';
import CookieConsent, { Cookies } from "react-cookie-consent";
import SobrePage from './pages/sobre/sobre.page';
import RedesSociaisPage from './pages/redes-sociais/redes-sociais.page';

function Routes() {

    const [lang, setLang] = React.useState<string>('');
    let isAPP = window.location.href.indexOf('app=1');

    return (
        <BrowserRouter>
            <HeaderComponent RouteLangHeader={lang} />
            <Switch>
                <Route exact path="/politica-de-privacidade" component={PoliticaPage} />
                <Route exact path="/sobre" component={SobrePage} />
                <Route exact path="/redes-sociais" component={RedesSociaisPage} />
                <Route exact path="/" component={() => <HomePage sendRouteLang={(lang: string) => { setLang(lang) }} />} />
                <Route exact path="/:RouteLang" component={() => <HomePage sendRouteLang={(lang: string) => { setLang(lang) }} />} />
                <Route exact path="/exportar-frase/:RouteLang/:idFrase/:aliasFrase" component={() => <ExportarFrasePage />} />
                {/* <Route exact path="/eventos" component={EventosPage} /> */}

                <Route component={NotFoundPage} />
            </Switch>
            <FooterComponent />
            <SnackComponent />
            <LoadingComponent />
            {
                isAPP == -1 ?
                    <CookieConsent
                        location="bottom"
                        buttonText="Aceitar"
                        cookieName="CookieConsent"
                        expires={150}
                    >
                        Este site usa cookies para melhorar a experiência do usuário.
                        <NavLink exact={true} to="/politica-de-privacidade" activeClassName="active">
                            <Button>Política de Privacidade</Button>
                        </NavLink>
                    </CookieConsent>
                    : null
            }
        </BrowserRouter>
    )
}

export default Routes;