import React from 'react';
import FraseModel from '../../pages/home/frase.model';
import './item-frase.component.scss';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from '@mui/material';
import { SnackService } from '../../services/snack.service';
import { useHistory } from 'react-router-dom';
import { TextosSiteProps } from '../header/header.component';

export type ItemFraseProps = {
  indexItem: number;
  entraSai: string;
  item: FraseModel;
}

function ItemFraseComponent(props: ItemFraseProps) {

  let history = useHistory();
  let textosSite: TextosSiteProps = JSON.parse(localStorage.getItem('textosSite') || '{}');
  let lang: string = localStorage.getItem('lang') || 'pt';

  const articleStructuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: props.item.it_categoria,
    description: props.item.it_frase,
    //image: "https://upload.wikimedia.org/wikipedia/commons/4/40/JSON-LD.svg",
    datePublished: props.item.it_data,
    author: {
      "@type": "Person",
      name: props.item.it_autor,
      //url: "https://twitter.com/johnny_reilly",
    },
  };

  const ExportarFrase = (item: FraseModel) => {
    history?.push({
      pathname: `/exportar-frase/${lang}/${item.it_id}/${item.it_alias}`
    })
  }

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(articleStructuredData)}
      </script>
      <div className={"flex flex-cv flex-ch item-frase " + props.entraSai}>
        <div className="txt">
          <h3>{props.item.it_categoria}</h3>
          <h2>{props.item.it_frase}</h2>
          <h4>{props.item.it_autor}</h4>
          {
            props.item.it_profissao &&
            <h5>{props.item.it_profissao}</h5>
          }
          {
            props.item.it_data &&
            <h6>({props.item.it_data})</h6>
          }
          <div className="area-bt">
            <Button className='bt' onClick={() => {
              ExportarFrase(props.item);
            }}>
              {textosSite._export_phase_}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ItemFraseComponent;
