import React, { useEffect, useLayoutEffect, useState } from 'react';
import './exportar-frase.page.scss';
import FraseModel from '../../pages/home/frase.model';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from '@mui/material';
import { SnackService } from '../../services/snack.service';
import { TextosSiteProps } from '../../components/header/header.component';
import { useParams } from 'react-router-dom';
import { GetFraseService } from '../../services/api-frases.service';
import { LoadingService } from '../../services/loading.service';
import { TranslateService } from '../../services/api-translate.service';
import UndoIcon from '@mui/icons-material/Undo';

function ExportarFrasePage() {

  useEffect(() => {
    GetFrase(parseInt(idFrase));
  }, []);

  let { RouteLang, idFrase } = useParams<{ RouteLang: string, idFrase: string }>();

  let lang: string = RouteLang;

  const [fraseItem, setFraseItem] = React.useState<FraseModel>();

  let textosSite: TextosSiteProps = JSON.parse(localStorage.getItem('textosSite') || '{}');

  const articleStructuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: fraseItem?.it_categoria,
    description: fraseItem?.it_frase,
    //image: "https://upload.wikimedia.org/wikipedia/commons/4/40/JSON-LD.svg",
    datePublished: fraseItem?.it_data,
    author: {
      "@type": "Person",
      name: fraseItem?.it_autor,
      //url: "https://twitter.com/johnny_reilly",
    },
  };

  const CopiarFrase = (txt: string) => {
    navigator.clipboard.writeText(txt);
    SnackService.sendMSG(textosSite._copy_);
  }

  const GetFrase = (id_frase: number) => {
    LoadingService.sendLoadingAtivo(true);
    GetFraseService({ id_frase: id_frase }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        if (lang === 'es') {
          setFraseItem(res.data[0]);
          ChangeTitle(fraseItem?.it_frase);
        } else {
          Translate(res.data[0], lang);
        }
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao consultar frase na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const Translate = (itemFrase: FraseModel, targetLang: string) => {
    TranslateService(JSON.stringify({
      it_00: itemFrase.it_frase,
      it_01: itemFrase.it_alias,
      it_02: itemFrase.it_profissao,
      it_03: itemFrase.it_categoria,
      it_04: itemFrase.it_titulo,
    }), localStorage.getItem('lang') || targetLang).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        // SnackService.sendMSG(res.data[0][0][0]);
        let objRes = JSON.parse((res.data[0][0][0])
        .replace(/ \":/g, '\":')
        .replace(/" :/g, '":')
        .replace(/"it_02 \"/g, '"it_02\"')
        .replace(/\" it/g, '\"it')
        );
        let objTraduzido = {
          it_id: itemFrase.it_id,
          it_frase: objRes.it_00,
          it_alias: RemoveSpecialCaracteres(objRes.it_00),
          it_autor: itemFrase.it_autor,
          it_data: itemFrase.it_data,
          it_profissao: objRes.it_02,
          it_categoria: objRes.it_03,
          it_titulo: objRes.it_04,
        };
        setFraseItem(objTraduzido);
        ChangeTitle(fraseItem?.it_frase);
        console.log(JSON.stringify(res.data[0][0][0]) + ' << ah');
        console.log(objTraduzido);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao traduzir frase.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const ChangeTitle = (frase?: string) => {
    console.log(frase + ' <<<<<<< frase');
    document.title = frase ? frase : 'Frases motivacionais, frases de amor, frases famosas, frase do dia e muito mais.';
  }

  const RemoveSpecialCaracteres = (str: string) => {
    return str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
      .toLowerCase()
      .replace(/ç/g, 'c').replace(/é/g, 'e')
      .replace(/ /g, '-')
      .replace(/ã/g, 'a')
      .replace(/å/g, 'a')
      .replace(/ą/g, 'a')
      .replace(/č/g, 'c')
      .replace(/õ/g, 'o')
      .replace(/á/g, 'a')
      .replace(/ó/g, 'o')
      .replace(/ú/g, 'u')
      .replace(/ú/g, 'u')
      .replace(/ü/g, 'u')
      .replace(/ë/g, 'e')
      .replace(/ę/g, 'e')
      .replace(/ń/g, 'n')
      .replace(/ś/g, 's')
      .replace(/ź/g, 'z')
      .replace(/ż/g, 'z')
      .replace(/í/g, 'i')
      .replace(/â/g, 'a')
      .replace(/à/g, 'a')
      .replace(/ê/g, 'e')
      .replace(/ô/g, 'o')
      .replace(/;/g, '')
  }

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(articleStructuredData)}
      </script>
      <section className="exportar-frase">
        {
          fraseItem &&
          <div className={"flex flex-cv flex-ch item-frase entra"}>
            <div className="txt">
              <h3>{fraseItem.it_categoria}</h3>
              <h2>{fraseItem.it_frase}</h2>
              <h4>{fraseItem.it_autor}</h4>
              {
                fraseItem.it_profissao &&
                <h5>{fraseItem.it_profissao}</h5>
              }
              {
                fraseItem.it_data &&
                <h6>({fraseItem.it_data})</h6>
              }
              <div className="area-bt">
                <Button className='bt-copy' onClick={() => {
                  CopiarFrase(`
${fraseItem?.it_categoria}
${fraseItem?.it_frase}
${fraseItem?.it_autor}
${fraseItem?.it_profissao}
(${fraseItem?.it_data})
frasesmotivacionais.a7apps.com
                `);
                }}>
                  <ContentCopyIcon />
                </Button>
              </div>
            </div>
          </div>
        }
        <Button className='bt-back' href={"/" + lang} >
          <UndoIcon /> <strong>{textosSite._back_page_}</strong>
        </Button>

      </section>
    </>
  );
}

export default ExportarFrasePage;
