import React, { useEffect } from 'react';
import { LoadingService } from '../../services/loading.service';
import { PageRedesSociaisService } from '../../services/pages.service';
import { SnackService } from '../../services/snack.service';
import './redes-sociais.page.scss';
import PageRedesSociaisModel from '../../models/page-redes-sociais.model';
import { Button } from '@mui/material';

function RedesSociaisPage() {

  const [page, setPage] = React.useState<PageRedesSociaisModel[]>();

  useEffect(() => {
    PageRedesSociais();
  }, []);

  const PageRedesSociais = () => {

    LoadingService.sendLoadingAtivo(true);
    PageRedesSociaisService().then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setPage(res.data);
        console.log(res.data, 'data');
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao carregar página.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  return (
    <>
      {
        page ?
          <>
            <section className="redes-sociais">
              <div className="container">
                <h1>Redes Sociais</h1>
                <div className="itens flex flex-cv flex-dc">
                  {
                    page.map((item, indexItem) => (
                      <Button key={indexItem} className={item.icone} href={item.url} target='_blank'></Button>
                    ))
                  }
                </div>
              </div>
            </section>
          </>
          :
          <>
          </>
      }

    </>
  );
}

export default RedesSociaisPage;
