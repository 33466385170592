import React, { useEffect } from 'react';
import './footer.component.scss';
import { sendEvent } from '../../scripts/events';
import BannerModel from '../../pages/home/banner.model';
import { GetBannerService } from '../../services/api-frases.service';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import Button from '@mui/material/Button';
import InstagramIcon from '@mui/icons-material/Instagram';

function FooterComponent() {
  const [bannerMKT, setBannerMKT] = React.useState<BannerModel>();
  var appVarLocalStorage = localStorage.getItem('appVar');
  var url_string = window.location.href;
  var url = new URL(url_string);
  var appVar = appVarLocalStorage ? appVarLocalStorage : url.searchParams.get("app");

  if (appVar) {
    localStorage.setItem('appVar', appVar);
  }

  useEffect(() => {
    setTimeout(() => {
      GetBanner();
    }, 500);
  }, []);

  const GetBanner = () => {
    LoadingService.sendLoadingAtivo(true);
    GetBannerService({ id_banner: 5 }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setBannerMKT(res.data[0]);
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
    });
  }

  return (
    <footer>
      <div className="container">
        <div className='flex flex-sp flex-cv'>
          <i></i>
          <div className="banners-mkt">
            {
              bannerMKT ?
                <>
                  {
                    bannerMKT.tipo === 'Imagem' ?
                      <div className="ban-img">
                        <Button onClick={() => {
                          sendEvent({ category: 'frasesmotivacionais', label: bannerMKT.name_ga, action: 'click' });
                        }} className="bt" href={bannerMKT.url} target="_blank">
                          <img src={bannerMKT.imagem} alt={bannerMKT.name_ga} />
                        </Button>
                      </div>
                      :
                      <Button onClick={() => {
                        sendEvent({ category: 'frasesmotivacionais', label: bannerMKT.name_ga, action: 'click' });
                      }} className="bt" href={bannerMKT.url} target="_blank">
                        <div className="ban-txt" dangerouslySetInnerHTML={{ __html: bannerMKT.texto }}></div>
                      </Button>
                  }
                </>
                : <></>
            }
          </div>
          {
            !appVar &&
            <Button onClick={() => {
              sendEvent({ category: 'frasesmotivacionais', label: 'bt_insta', action: 'click' });
            }} className='bt-insta' href='https://www.instagram.com/frasesmotivacionais.a7apps/' target='_blank'>
              <InstagramIcon />
            </Button>
          }
        </div>
      </div>
    </footer>
  );
}

export default FooterComponent;
