import React, { useEffect } from 'react';
import PageModel from '../../models/page.model';
import { LoadingService } from '../../services/loading.service';
import { PagePoliticaService, PageSobreService } from '../../services/pages.service';
import { SnackService } from '../../services/snack.service';
import './sobre.page.scss';

function SobrePage() {

  const [page, setPage] = React.useState<PageModel>();

  useEffect(() => {
    PageSobre();
  }, []);

  const PageSobre = () => {

    LoadingService.sendLoadingAtivo(true);
    PageSobreService().then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setPage(res.data);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao carregar página.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  return (
    <>
      {
        page ?
          <>
            <section className="sobre">
              <div className="container">
                <h1>{page?.title}</h1>
                <div className="txt" dangerouslySetInnerHTML={{ __html: page.content }}></div>
              </div>
            </section>
          </>
          :
          <>
          </>
      }

    </>
  );
}

export default SobrePage;
