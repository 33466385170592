import axios from "axios";
import { environment } from "../environments/environment";

const config: any = {
    //headers: {}
};

export const api = axios.create({
    //baseURL: environment.apiMockUrl
    baseURL: environment.serverUrl
});

export async function ListarFrasesService(dto: { user_email: string,  idsFrases: number[]}) {
    let url = '/list-frases';
    return await api.post(url, dto, config);
}

export async function GetFraseService(dto: { id_frase: number }) {
    let url = '/get-frase';
    return await api.post(url, dto, config);
}

export async function GetBannerService(dto: { id_banner: number }) {
    let url = '/get-banner';
    return await api.post(url, dto, config);
}