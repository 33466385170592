import { NavLink, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import './header.component.scss';
import React, { useEffect } from "react";
import { Menu, MenuItem } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { TranslateService } from "../../services/api-translate.service";
import { LoadingService } from "../../services/loading.service";
import { SnackService } from "../../services/snack.service";

export type HeaderComponentProps = {
  RouteLangHeader: string;
}

export type TextosSiteProps = {
  _copy_: string;
  _export_phase_: string;
  _back_page_: string;
}

function HeaderComponent(props: HeaderComponentProps) {

  //let { RouteLang } = useParams<{ RouteLang: string }>();
  let langLocalStorage: string = localStorage.getItem('lang') || '';
  const [lang, setLang] = React.useState<string>(langLocalStorage ? langLocalStorage : props.RouteLangHeader ? props.RouteLangHeader : 'pt');
  const [txtH1, setTxtH1] = React.useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [textosSitePT, setTextosSitePT] = React.useState<TextosSiteProps>({
    _copy_: 'Frase copiada',
    _export_phase_: 'Compartilhar frase',
    _back_page_: 'Voltar',
  });
  const [textosSiteEN, setTextosSiteEN] = React.useState<TextosSiteProps>({
    _copy_: 'Quote copied',
    _export_phase_: 'Share quote',
    _back_page_: 'Come back',
  });
  const [textosSiteES, setTextosSiteES] = React.useState<TextosSiteProps>({
    _copy_: 'Cita copiada',
    _export_phase_: 'Compartir oración',
    _back_page_: 'Vuelve',
  });

  useEffect(() => {
    setLang(props.RouteLangHeader);
  }, [props]);

  useEffect(() => {
    VerificaLang();
  }, [lang]);

  const ChooseLang = (lang: string) => {
    setLang(lang);
    closeMenu();
    VerificaLang();
  }

  const VerificaLang = () => {
    if (lang === 'es') {
      setTxtH1('Frases motivacionales');
      localStorage.setItem('textosSite', JSON.stringify(textosSiteES));
    } else if (lang === 'pt') {
      setTxtH1('Frases motivacionais');
      localStorage.setItem('textosSite', JSON.stringify(textosSitePT));
    } else if (lang === 'en') {
      setTxtH1('Motivational quotes');
      localStorage.setItem('textosSite', JSON.stringify(textosSiteEN));
    } else {
      setTimeout(() => {
        TranslateH1();
      }, 250);
      setTimeout(() => {
        TranslateTextosSite();
      }, 500);
    }

    localStorage.setItem('lang', lang);
  }

  const clickLang = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const TranslateH1 = () => {
    TranslateService('Frases motivacionales', lang).then(res => {
      //LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        // SnackService.sendMSG(res.data[0][0][0]);
        setTxtH1(res.data[0][0][0]);
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão. (TranslateH1)');
      //LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const TranslateTextosSite = () => {
    TranslateService(JSON.stringify({
      it_00: textosSiteES._copy_,
      it_01: textosSiteES._export_phase_,
      it_02: textosSiteES._back_page_,
    }), lang).then(res => {
      //LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        // SnackService.sendMSG(res.data[0][0][0]);
        let objTraduzido = {
          _copy_: JSON.parse(res.data[0][0][0]).it_00,
          _export_phase_: JSON.parse(res.data[0][0][0]).it_01,
          _back_page_: JSON.parse(res.data[0][0][0]).it_02,
        };
        localStorage.setItem('textosSite', JSON.stringify(objTraduzido));
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão. (TranslateTextosSite)');
      //LoadingService.sendLoadingAtivo(false);
      console.log(err + ' << err');
    });
  }

  return (
    <>
      <header>
        <div className='box-logo flex flex-sp flex-cv'>
          <h1>
            <a href={"/" + lang} title={ txtH1 }>
              {txtH1}
            </a>
          </h1>
          <Button className="bt-lang"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={clickLang}
          >
            <LanguageIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem className={lang === 'pt' ? 'ativo' : ''}>
              <NavLink onClick={() => ChooseLang('pt')} to="/">
                pt
              </NavLink>
            </MenuItem>
            <MenuItem className={lang === 'en' ? 'ativo' : ''}>
              <NavLink onClick={() => ChooseLang('en')} to="/en">
                en
              </NavLink>
            </MenuItem>
            <MenuItem className={lang === 'es' ? 'ativo' : ''}>
              <NavLink to="/es" onClick={() => ChooseLang('es')}>
                es
              </NavLink>
            </MenuItem>
            {
              navigator.language.split('-')[0] != 'pt' &&
              navigator.language.split('-')[0] != 'en' &&
              navigator.language.split('-')[0] != 'es' &&
              <MenuItem onClick={() => ChooseLang(navigator.language.split('-')[0])} className={lang === navigator.language.split('-')[0] ? 'ativo' : ''}>
                {navigator.language.split('-')[0]}
              </MenuItem>
            }
          </Menu>
        </div>
      </header>
    </>
  );
}

export default HeaderComponent;
