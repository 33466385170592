import axios from "axios";
import { environment } from "../environments/environment";

const config: any = {
    //headers: {}
};

export const api = axios.create({
    baseURL: environment.serverUrl
});

export async function PagePoliticaService() {
    let url = '/politica-de-privacidade';
    return await api.post(url, { email: 'tutors@' }, config);
}

export async function PageSobreService() {
    let url = '/sobre';
    return await api.post(url, { email: 'tutors@' }, config);
}

export async function PageRedesSociaisService() {
    let url = '/redes-sociais';
    return await api.post(url, { email: 'tutors@' }, config);
}
