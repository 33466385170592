import axios from "axios";
import { environment } from "../environments/environment";

const config: any = {
    //headers: {}
};

export const api = axios.create({
    //baseURL: environment.apiMockUrl
    baseURL: 'https://translate.googleapis.com/translate_a/'
});

export async function TranslateService(text: string, targetLang: string) {
    var sourceLang = 'es';
    var targetLang = targetLang;
    let url = "single?client=gtx&sl=" + sourceLang + "&tl=" + targetLang + "&dt=t&q=" + encodeURI(text);
    //console.log(url);

    // $.getJSON(url, function (data) {
    //     $('textarea#resultText').val(data[0][0][0]);
    // });

    return await api.get(url, config);
}