import React, { useEffect } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import BallotIcon from '@mui/icons-material/Ballot';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import FraseModel from './frase.model';

import './home.page.scss';
import ItemFraseComponent from '../../components/item-frase/item-frase.component';
import { GetBannerService, ListarFrasesService } from '../../services/api-frases.service';
import { SnackService } from '../../services/snack.service';
import { LoadingService } from '../../services/loading.service';
import { TranslateService } from '../../services/api-translate.service';
import { useParams } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import BannerModel from './banner.model';
import { sendEvent } from '../../scripts/events';

export type HomePageProps = {
  sendRouteLang: (lang: string) => void;
}

export default function HomePage(props: HomePageProps) {
  const [openModal, setOpenModal] = React.useState(false);
  const [listFrases, setListFrases] = React.useState<Array<FraseModel>>([]);
  const [itemAtual, setItemAtual] = React.useState<number>(0);
  const [listIDs, setListIDs] = React.useState<Array<number>>([]);
  let langLocalStorage: string = localStorage.getItem('lang') || '';
  let { RouteLang } = useParams<{ RouteLang: string }>();
  const [lang, setLang] = React.useState<string>(langLocalStorage ? langLocalStorage : RouteLang ? RouteLang : 'pt');

  useEffect(() => {
    LoadingService.sendLoadingAtivo(true);
    setTimeout(() => {
      ListarFrases();
    }, 500);
    props.sendRouteLang(lang);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setItemAtual(listIDs.length - 1);
    }, 200);
  }, [listIDs])

  const NextSlide = () => {
    if (itemAtual < listFrases.length - 1) {
      setItemAtual(itemAtual + 1);
    } else {
      ListarFrases();
    }
  }

  const PrevSlide = () => {
    if (itemAtual > 0) {
      setItemAtual(itemAtual - 1);
    }
  }

  const RemoveSpecialCaracteres = (str: string) => {
    return str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
      .toLowerCase()
      .replace(/ç/g, 'c').replace(/é/g, 'e')
      .replace(/ /g, '-')
      .replace(/ã/g, 'a')
      .replace(/å/g, 'a')
      .replace(/ą/g, 'a')
      .replace(/č/g, 'c')
      .replace(/õ/g, 'o')
      .replace(/á/g, 'a')
      .replace(/ó/g, 'o')
      .replace(/ú/g, 'u')
      .replace(/ú/g, 'u')
      .replace(/ü/g, 'u')
      .replace(/ë/g, 'e')
      .replace(/ę/g, 'e')
      .replace(/ń/g, 'n')
      .replace(/ś/g, 's')
      .replace(/ź/g, 'z')
      .replace(/ż/g, 'z')
      .replace(/í/g, 'i')
      .replace(/â/g, 'a')
      .replace(/à/g, 'a')
      .replace(/ê/g, 'e')
      .replace(/ô/g, 'o')
      .replace(/;/g, '')
  }

  const ListarFrases = () => {
    LoadingService.sendLoadingAtivo(true);
    ListarFrasesService({ user_email: 'email', idsFrases: listIDs }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        if (lang === 'es') {
          setListIDs([...listIDs, res.data[0].it_id]);
          setListFrases([...listFrases, res.data[0]]);
        } else {
          Translate(res.data[0], lang);
        }
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao consultar frase na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const Translate = (itemFrase: FraseModel, targetLang: string) => {
    TranslateService(JSON.stringify({
      it_00: itemFrase.it_frase,
      it_01: itemFrase.it_alias,
      it_02: itemFrase.it_profissao,
      it_03: itemFrase.it_categoria,
      it_04: itemFrase.it_titulo,
    }), localStorage.getItem('lang') || targetLang).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        // SnackService.sendMSG(res.data[0][0][0]);
        let objTraduzido = {
          it_id: itemFrase.it_id,
          it_frase: JSON.parse(res.data[0][0][0]).it_00,
          it_alias: RemoveSpecialCaracteres(JSON.parse(res.data[0][0][0]).it_00),
          it_autor: itemFrase.it_autor,
          it_data: itemFrase.it_data,
          it_profissao: JSON.parse(res.data[0][0][0]).it_02,
          it_categoria: JSON.parse(res.data[0][0][0]).it_03,
          it_titulo: JSON.parse(res.data[0][0][0]).it_04,
        };
        setListFrases([...listFrases, objTraduzido]);
        console.log(objTraduzido, 'objTranslate');
        setListIDs([...listIDs, itemFrase.it_id]);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao traduzir frase.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  return (
    <>
      <section className="home">
        <div className="itens flex flex-cv flex-ch">
          {
            listFrases ? listFrases?.map((item, indexItem) => (
              <ItemFraseComponent
                key={indexItem}
                indexItem={indexItem}
                entraSai={indexItem === itemAtual ? "entra" : "sai"}
                item={item}
              />
            )) : <span>Nenhum item encontrado.</span>
          }
        </div>

        <div className="bts flex flex-sp">
          <Button onClick={() => {
            sendEvent({ category: 'frasesmotivacionais', label: 'bt_seta_prev', action: 'click' });
            PrevSlide();
          }} className={itemAtual === 0 ? 'bt inativo' : 'bt'} >
            <ArrowBackIosNewIcon />
          </Button>
          <Button onClick={() => {
            sendEvent({ category: 'frasesmotivacionais', label: 'bt_seta_next', action: 'click' });
            NextSlide();
          }} className="bt" >
            <ArrowForwardIosIcon />
          </Button>
        </div>
        <Button onClick={() => {
          sendEvent({ category: 'frasesmotivacionais', label: 'bt_burger', action: 'click' });
          setOpenModal(true);
        }} className='bt-list' >
          <MenuIcon />
        </Button>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            <ul className="itens">
              {
                listFrases ? listFrases.map((item, indexItem) => (
                  <li key={indexItem} className={(itemAtual === indexItem ? 'inativo' : '')}>
                    <Button className="bt" onClick={() => {
                      setItemAtual(indexItem);
                      setOpenModal(false);
                    }}>
                      {item.it_frase}
                    </Button>
                  </li>
                )) : <span>Nenhum item encontrado.</span>
              }
            </ul>
          </Box>
        </Modal>
      </section>
    </>
  );
}